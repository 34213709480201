//
import PProviderService from '@/services/api/Provider/PProvider'
import RulesService from '@/services/rules'

//
export default {
  name: 'v-view-provider',
  data() {
    return {
      propsSubmit:{
        color:'secondary',
        plain:false,
        text:false,
      },
      resetProps:{
        color:'primary',
        text:false,
      },
      busy: false,
      pProvider: {},
    }
  },
  methods: {

    /**
     * 
     */
    async handleFormReset() {
      this.busy = true
      this.pProvider = await PProviderService.getById(this.$provider.id)
      this.busy = false
    },

    /**
     * 
     */
    async handleFormSubmit() {
      this.busy = true
      await PProviderService.video(this.pProvider).then(this.handleVideoSuccess).catch(this.handleVideoError)
      this.busy = false
    },

    /**
     * 
     */
    handleVideoError(payload) {
      PProviderService.translate(payload).then(this.$toast.error)
    },

    /**
     * 
     */
    handleVideoSuccess(payload) {
      PProviderService.translate({ response: payload }).then(this.$toast.success)
    },
  },
  created() {
    this.handleFormReset()
  },
  props: {

    /**
     * 
     */
    videoRules: {
      type: Array,
      default: () => [
        RulesService.required,
        RulesService.url,
        RulesService.max(120),
      ],
    },
  }
}